
import {defineComponent, onMounted, ref, watch} from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { VueCookieNext } from "vue-cookie-next";
import { apiEndpoint } from "@/mixin/apiMixin.js";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "topbar",
  components: {
    KTUserMenu,
  },
  setup() {
    const employeeName = ref("Loading...");
    const role = ref("Loading...");

    const employeeData = async () => {
      try {
        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_EMPLOYEE_SHOW,
            {
              user_id: VueCookieNext.getCookie("_user_id"),
            }
        );
        employeeName.value = response.data.data.name;
        role.value = response.data.data.role_name;

        console.log(employeeName.value);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    onMounted(async () => {
      await employeeData();
    });

    return { employeeName, role };
  },
});
